import { Stack } from "@mui/joy";
import Sidebar from "../sidebar";

export const SIDEBAR_WIDTH = 230;

export default function Layout({ children }) {
  return (
    <Stack direction="row">
      <Sidebar width={SIDEBAR_WIDTH} />
      {children}
    </Stack>
  );
}
