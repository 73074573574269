import { useSelector } from "react-redux";

import { Grid, Stack, Typography } from "@mui/joy";

export default function Body() {
  const { affiliateCode } = useSelector((state) => state.affiliate);
  const { code } = affiliateCode;

  return (
    <Grid container pt={3}>
      <Grid xs={12}>
        <Stack spacing={2}>
          <Typography level="title-md">Affiliate Code</Typography>
          <Stack
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            px={2}
            py={2}
            sx={{
              bgcolor: "#fff",
              border: "2px dashed #e2e2e2",
            }}
          >
            <Typography textAlign="center" level="body-lg" fontWeight={700}>
              {code}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}
