import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setActivePathId, setNotify } from "../../../redux/utils";

import urls from "../../../utils/urls.json";
import { DOMAIN } from "../../../utils/config";

import axios from "axios";
import AuthWrapper from "../wrapper";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Typography,
} from "@mui/joy";
import { ArrowBackIos, Email } from "@mui/icons-material";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setActivePathId(urls.auth.children.forgotPassword.pathId));
  }, [dispatch]);

  const handleSubmit = async (e) => {
    // handle form submit

    e.preventDefault();
    // remove errors from state, if any
    setError(null);
    // set loading to true
    setLoading(true);

    try {
      // make API request
      await axios({
        method: "POST",
        url: `${DOMAIN}/auth/password/reset/`,
        data: {
          email,
        },
      });

      // set loading to false
      setLoading(false);

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Email Successfully Sent! (if email-id is valid)",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );
    } catch (err) {
      // set loading to false
      setLoading(false);

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Unable to send email!",
          severity: "error",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );
    }
  };

  const handleChange = (func, value) => {
    // handle change of input values

    // remove errors from state, if anny
    setError(null);
    func(value);
  };

  return (
    <AuthWrapper>
      <Stack spacing={2}>
        <Typography level="h3" component="h1" textAlign="center">
          Forgot Password
        </Typography>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Stack spacing={1.5}>
            <FormControl error={Boolean(error)}>
              <FormLabel>Email</FormLabel>
              <Input
                required
                size="sm"
                type="email"
                placeholder="john.doe@example.com"
                value={email}
                onChange={(e) => handleChange(setEmail, e.target.value)}
              />
              {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
            <Button
              fullWidth
              size="sm"
              type="submit"
              disabled={loading}
              endDecorator={<Email fontSize="small" />}
              color="neutral"
            >
              Get Email
            </Button>
          </Stack>
        </form>
        <Button
          fullWidth
          size="sm"
          variant="plain"
          startDecorator={<ArrowBackIos />}
          onClick={() => navigate(urls.auth.children.login.absolutePath)}
          color="neutral"
        >
          Back to Login
        </Button>
      </Stack>
    </AuthWrapper>
  );
}
