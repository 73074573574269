import { Stack } from "@mui/joy";
import Header from "./header";
import { SIDEBAR_WIDTH } from "../../../components/layout";

export default function BodyWrapper({ children }) {
  const { innerWidth } = window;

  return (
    <Stack
      sx={{
        width: {
          xs: `${innerWidth}px`,
          lg: `${innerWidth - SIDEBAR_WIDTH}px`,
        },
      }}
    >
      <Header />
      <Stack
        sx={{
          height: "100%",
          padding: {
            xs: "5px 20px",
            lg: "15px 35px",
          },
        }}
        spacing={2}
      >
        {children}
      </Stack>
    </Stack>
  );
}
