import { createSlice } from "@reduxjs/toolkit";

export const affiliateSlice = createSlice({
  name: "affiliate",
  initialState: {
    fetchedAffiliates: false,

    affiliateCode: {},
    affiliates: [],
  },
  reducers: {
    setFetchedAffiliates: (state, { payload }) => {
      state.fetchedAffiliates = payload;
    },
    setAffiliateCode: (state, { payload }) => {
      state.affiliateCode = payload;
    },
    setAffiliates: (state, { payload }) => {
      state.affiliates = payload;
    },
  },
});

export const {
  setFetchedAffiliates,

  setAffiliateCode,
  setAffiliates,
} = affiliateSlice.actions;

export default affiliateSlice.reducer;
