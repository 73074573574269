import { useState } from "react";

import { useDispatch } from "react-redux";
import { setNotify } from "../../../../redux/utils";

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  Stack,
  FormHelperText,
} from "@mui/joy";

import { Edit } from "@mui/icons-material";

import { DOMAIN } from "../../../../utils/config";
import { getAuthorizationHeader } from "../../../../utils/helpers";

import axios from "axios";

export default function UpdatePassword() {
  const [open, setOpen] = useState(false);

  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const [error, setError] = useState("");
  const [errorNewPassword1, setErrorNewPassword1] = useState("");
  const [errorNewPassword2, setErrorNewPassword2] = useState("");

  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    setSaving(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/auth/password/change/`,
      headers: getAuthorizationHeader(),
      data: {
        new_password1: newPassword1,
        new_password2: newPassword2,
      },
    })
      .then(() => {
        setNewPassword1("");
        setNewPassword2("");
        setSaving(false);
        dispatch(
          setNotify({
            open: true,
            action: "Password Updated!",
            severity: "success",
          })
        );
        setOpen(false);
      })
      .catch((err) => {
        setSaving(false);
        try {
          // fetch error
          let error_object = JSON.parse(err.request.response);

          // set error
          setErrorNewPassword1(
            error_object.new_password1 && error_object.new_password1[0]
          );
          setErrorNewPassword2(
            error_object.new_password2 && error_object.new_password2[0]
          );
          setError(
            error_object.non_field_errors && error_object.non_field_errors[0]
          );
        } catch {
          // default error message
          setError("Something Went Wrong!");
        }
      });
  };

  return (
    <>
      <Button
        variant="outlined"
        color="neutral"
        size="sm"
        startDecorator={<Edit fontSize="small" />}
        onClick={() => setOpen(true)}
      >
        Update Password
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <DialogTitle>Update Password</DialogTitle>
          <DialogContent>
            Fill in the information to update your password.
          </DialogContent>
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <FormControl error={Boolean(errorNewPassword1) || Boolean(error)}>
                <FormLabel>New Password</FormLabel>
                <Input
                  required
                  size="sm"
                  value={newPassword1}
                  type="text"
                  onChange={(e) => setNewPassword1(e.target.value)}
                />
                {Boolean(errorNewPassword1) && (
                  <FormHelperText>{errorNewPassword1}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={Boolean(errorNewPassword2) || Boolean(error)}>
                <FormLabel>Confirm New Password</FormLabel>
                <Input
                  required
                  size="sm"
                  value={newPassword2}
                  type="password"
                  onChange={(e) => setNewPassword2(e.target.value)}
                />
                {Boolean(errorNewPassword2) || Boolean(error) ? (
                  <FormHelperText>{errorNewPassword2 || error}</FormHelperText>
                ) : null}
              </FormControl>
              <Button type="submit" size="sm" color="neutral" disabled={saving}>
                {saving ? "Saving" : "Save"}
              </Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </>
  );
}
