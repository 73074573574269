import { useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setNotify } from "../../../redux/utils";

import urls from "../../../utils/urls.json";
import { DOMAIN } from "../../../utils/config";

import axios from "axios";
import AuthWrapper from "../wrapper";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Typography,
} from "@mui/joy";

export default function ResetPassword() {
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const { uid, token } = useParams();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);
  const [errorPassword1, setErrorPassword1] = useState(null);
  const [errorPassword2, setErrorPassword2] = useState(null);

  // redux
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    // handle form submit
    e.preventDefault();

    // remove errors from state, if any
    setError(null);
    setErrorPassword1(null);
    setErrorPassword2(null);

    // set loading to true
    setLoading(true);

    try {
      // make API request
      await axios({
        method: "POST",
        url: `${DOMAIN}/auth/password/reset/confirm/`,
        data: {
          uid: uid,
          token,
          new_password1: newPassword1,
          new_password2: newPassword2,
        },
      });

      setLoading(false);

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Password changed!",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      navigate(urls.auth.children.login.absolutePath);
    } catch (err) {
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);

        // set error
        setErrorPassword1(
          error_object.new_password1 && error_object.new_password1[0]
        );
        setErrorPassword2(
          error_object.new_password2 && error_object.new_password2[0]
        );
        setError(
          error_object.non_field_errors && error_object.non_field_errors[0]
        );
      } catch {
        // default error message
        setError("Something Went Wrong!");
      }
    }
  };

  const handleChange = (func, value) => {
    // handle change of input values

    // remove errors from state, if anny
    setError(null);
    setErrorPassword1(null);
    setErrorPassword2(null);
    func(value);
  };

  return (
    <AuthWrapper>
      <Stack spacing={2}>
        <Typography level="h3" component="h1" textAlign="center">
          {urls.auth.children.resetPassword.name}
        </Typography>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Stack spacing={1.5}>
            <FormControl error={Boolean(errorPassword1 || error)}>
              <FormLabel>Password</FormLabel>
              <Input
                required
                type="text"
                placeholder="Choose a Secure Password"
                value={newPassword1}
                onChange={(e) => handleChange(setNewPassword1, e.target.value)}
              />
              {Boolean(errorPassword1) && (
                <FormHelperText>{errorPassword1}</FormHelperText>
              )}
            </FormControl>
            <FormControl error={Boolean(errorPassword2 || error)}>
              <FormLabel>Confirm Password</FormLabel>
              <Input
                required
                type="password"
                placeholder="**********"
                value={newPassword2}
                onChange={(e) => handleChange(setNewPassword2, e.target.value)}
              />
              {Boolean(errorPassword2) ||
                (Boolean(error) && (
                  <FormHelperText>{errorPassword2 || error}</FormHelperText>
                ))}
            </FormControl>
            <Button
              fullWidth
              size="sm"
              color="neutral"
              type="submit"
              disabled={loading}
            >
              {loading ? "Changing" : "Change"} password
            </Button>
          </Stack>
        </form>
      </Stack>
    </AuthWrapper>
  );
}
