import { Stack } from "@mui/joy";
import EarningsList from "./earningsList";

export default function Body() {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "200px",
      }}
    >
      <EarningsList />
    </Stack>
  );
}
