import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setActivePathId, setNotify } from "../../../redux/utils";

import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Stack,
  Typography,
} from "@mui/joy";

import { DOMAIN } from "../../../utils/config";
import { saveUserCredentials } from "../../../utils/helpers";

import urls from "../../../utils/urls.json";

import axios from "axios";
import AuthWrapper from "../wrapper";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setActivePathId(urls.auth.children.login.pathId));
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // remove errors from state, if any
    setError(null);
    // set loading to true
    setLoading(true);

    try {
      // make API request
      const response = await axios({
        method: "POST",
        url: `${DOMAIN}/auth/login/`,
        data: {
          email,
          password,
        },
      });
      // save user credentials
      saveUserCredentials(response.data);
      // redirect to home page

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Logged in Successfully!",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      navigate(urls.dashboard.absolutePath);
    } catch (err) {
      // set loading to false
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);
        // set error
        setError(error_object.non_field_errors[0]);
      } catch {
        // default error message
        setError("Unable to log in with provided credentials");
      }
    }
  };

  const handleChange = (func, value) => {
    // remove errors from state, if anny
    setError(null);
    func(value);
  };

  return (
    <AuthWrapper>
      <Stack spacing={2}>
        <Typography level="h3" component="h1" textAlign="center">
          {urls.auth.children.login.name}
        </Typography>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Stack spacing={1.5}>
            <FormControl error={Boolean(error)}>
              <FormLabel>Email</FormLabel>
              <Input
                required
                size="sm"
                type="email"
                placeholder="john.doe@example.com"
                value={email}
                onChange={(e) => handleChange(setEmail, e.target.value)}
              />
              {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
            <FormControl error={Boolean(error)}>
              <FormLabel>Password</FormLabel>
              <Input
                required
                size="sm"
                type="password"
                placeholder="xxxxxxxxxx"
                value={password}
                onChange={(e) => handleChange(setPassword, e.target.value)}
              />
              {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
            <Stack direction="row" justifyContent="flex-end">
              <Link
                onClick={() =>
                  navigate(urls.auth.children.forgotPassword.absolutePath)
                }
              >
                <Typography level="body-xs" textAlign="right">
                  {urls.auth.children.forgotPassword.name}
                </Typography>
              </Link>
            </Stack>
            <Button
              fullWidth
              size="sm"
              type="submit"
              disabled={loading}
              color="neutral"
            >
              {loading ? "Logging In" : "Log In"}
            </Button>
          </Stack>
        </form>
        <Button
          fullWidth
          size="sm"
          onClick={() => navigate(urls.auth.children.signup.absolutePath)}
          variant="plain"
          color="neutral"
        >
          Don't have an Account?
        </Button>
      </Stack>
    </AuthWrapper>
  );
}
