import { BrowserRouter, Routes, Route } from "react-router-dom";

// auth pages
import Login from "./pages/auth/login";
import Signup from "./pages/auth/signup";
import ForgotPassword from "./pages/auth/forgotPassword";
import ResetPassword from "./pages/auth/resetPassword";
import SignupVerification from "./pages/auth/signupVerification";

// account pages
import Account from "./pages/account/account";

// hoc
import AppWrapper from "./hoc/wrapper";
import Protected from "./hoc/protected";
import Unprotected from "./hoc/unprotected";
import AnyAuth from "./hoc/anyAuth";

// utils
import urls from "./utils/urls.json";
import Summary from "./pages/dashboard/summary";
import Earnings from "./pages/dashboard/earnings";
import Payout from "./pages/dashboard/payout";
import Codes from "./pages/dashboard/codes";

export default function App() {
  const authRoutes = () => (
    <>
      <Route path={urls.auth.path} element={<Unprotected />}>
        <Route path={urls.auth.children.login.path} element={<Login />}></Route>
        <Route
          path={urls.auth.children.signup.path}
          element={<Signup />}
        ></Route>
        <Route
          path={urls.auth.children.forgotPassword.path}
          element={<ForgotPassword />}
        ></Route>
        <Route
          path={urls.auth.children.resetPassword.path}
          element={<ResetPassword />}
        ></Route>
      </Route>
      <Route path={urls.auth.path} element={<AnyAuth />}>
        <Route
          path={urls.auth.children.signupVerification.path}
          element={<SignupVerification />}
        ></Route>
      </Route>
    </>
  );

  const accountRoutes = () => (
    <Route path={urls.account.path} element={<Protected />}>
      <Route
        path={urls.account.children.account.path}
        element={<Account />}
      ></Route>
    </Route>
  );

  const dashboardRoues = () => (
    <Route path={urls.dashboard.path} element={<Protected restaurantLayout />}>
      <Route
        path={urls.dashboard.children.summary.path}
        element={<Summary />}
      ></Route>
      <Route
        path={urls.dashboard.children.earnings.path}
        element={<Earnings />}
      ></Route>
      <Route
        path={urls.dashboard.children.payout.path}
        element={<Payout />}
      ></Route>
      <Route
        path={urls.dashboard.children.codes.path}
        element={<Codes />}
      ></Route>
    </Route>
  );

  return (
    <BrowserRouter>
      <AppWrapper>
        <Routes>
          {dashboardRoues()}
          {accountRoutes()}
          {authRoutes()}
        </Routes>
      </AppWrapper>
    </BrowserRouter>
  );
}
