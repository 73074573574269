import { Card, Stack, Typography } from "@mui/joy";
import { Chart as ChartJS, defaults } from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import revenueData from "./data/revenueData.json";
import urls from "../../../utils/urls.json";

export default function DailyEarnings() {
  defaults.maintainAspectRatio = false;
  defaults.responsive = true;

  defaults.plugins.title.display = true;
  defaults.plugins.title.align = "start";
  defaults.plugins.title.font.size = 20;
  defaults.plugins.title.color = "black";

  console.log(typeof ChartJS === "function");

  const data = [];

  return (
    <Card
      sx={{
        bgcolor: "#fff",
        maxHeight: "500px",
        minHeight: "200px",
        "&:hover": {
          border: "1px solid #000",
        },
      }}
    >
      {data.length ? (
        <Line
          data={{
            labels: revenueData.map((data) => data.label),
            datasets: [
              {
                label: "Revenue",
                data: revenueData.map((data) => data.revenue),
                backgroundColor: "#064FF0",
                borderColor: "#064FF0",
              },
            ],
          }}
          options={{
            elements: {
              line: {
                tension: 0.5,
              },
            },
            plugins: {
              title: {
                text: "Daily Earning",
              },
            },
          }}
        />
      ) : (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            minHeight: "200px",
          }}
          spacing={1.5}
        >
          <Typography
            textAlign="center"
            level={"title-lg"}
            fontWeight={400}
            textColor={"neutral.400"}
          >
            Not Enough Data
          </Typography>
          <Link to={urls.dashboard.children.codes.absolutePath}>
            <Typography
              textAlign="center"
              level={"title-sm"}
              fontWeight={400}
              textColor={"neutral.500"}
              sx={{
                textDecoration: "underline",
              }}
            >
              Share your Affiliate Code
            </Typography>
          </Link>
        </Stack>
      )}
    </Card>
  );
}
