import { Card, Stack, Typography } from "@mui/joy";

export default function PaymentCard({ title, description, amount, color }) {
  return (
    <Card
      sx={{
        bgcolor: "#fff",
        cursor: "pointer",
        "&:hover": {
          border: "1px solid #000",
        },
      }}
    >
      <Stack
        spacing={2}
        justifyContent="space-between"
        sx={{
          minHeight: "150px",
        }}
      >
        <Stack spacing={1}>
          <Typography textAlign="center" level="title-lg" fontWeight={700}>
            {title}
          </Typography>
          <Typography
            textAlign="center"
            level="body-xs"
            textColor="neutral.400"
          >
            {description}
          </Typography>
        </Stack>
        <Typography
          textAlign="center"
          level={amount ? "h1" : "title-lg"}
          fontWeight={amount ? 700 : 400}
          textColor={amount ? color : "neutral.400"}
        >
          {amount ? `₹ ${amount}` : "Not Enough Data"}
        </Typography>
      </Stack>
    </Card>
  );
}
