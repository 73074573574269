import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

// redux
import { useDispatch } from "react-redux";
import { setActivePathId } from "../../../redux/utils";

import urls from "../../../utils/urls.json";
import { DOMAIN } from "../../../utils/config";

import axios from "axios";
import AuthWrapper from "../wrapper";
import { Button, CircularProgress, Stack, Typography } from "@mui/joy";

export default function SignupVerification() {
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(null);

  // redux
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // react-router-dom
  const { key } = useParams();

  useEffect(() => {
    axios({
      method: "POST",
      url: `${DOMAIN}/auth/registration/verify-email/`,
      data: {
        key,
      },
    })
      .then(() => {
        setVerified(true);
      })
      .catch((err) => {
        console.error(err);
        setError("Unable to verify!");
      });
  }, [key]);

  useEffect(() => {
    dispatch(setActivePathId(urls.auth.children.signupVerification.pathId));
  }, [dispatch]);

  return (
    <AuthWrapper>
      <Stack spacing={2}>
        {Boolean(verified) ? (
          <>
            <Typography level="body-sm" textAlign="center" py={2}>
              Email Verified Successfully
            </Typography>
            <Button
              size="sm"
              color="neutral"
              onClick={() => navigate(urls.auth.children.login.absolutePath)}
            >
              Continue
            </Button>
          </>
        ) : error ? (
          <>
            <Typography level="body-sm" textAlign="center" py={2}>
              {error}
            </Typography>
            <Button
              size="sm"
              color="neutral"
              onClick={() => {
                navigate(urls.auth.children.login.absolutePath);
              }}
            >
              Continue to Log In
            </Button>
          </>
        ) : (
          <Stack alignItems="center" py={3}>
            <CircularProgress />
          </Stack>
        )}
      </Stack>
    </AuthWrapper>
  );
}
