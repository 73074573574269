import { useSelector } from "react-redux";

import { Sheet, Stack, Typography } from "@mui/joy";
import Table from "@mui/joy/Table";
import PayoutItem from "./payoutItem";

import { Link } from "react-router-dom";
import urls from "../../../utils/urls.json";

export default function PayoutList() {
  const { affiliates } = useSelector((state) => state.affiliate);

  return affiliates.length ? (
    <Sheet
      sx={{
        bgcolor: "#fff",
        px: 2,
        maxWidth: "100%",
        overflowX: "scroll",
      }}
    >
      <Table
        borderAxis="xBetween"
        color="neutral"
        size="lg"
        stickyFooter
        stickyHeader
        variant="plain"
        sx={{
          minWidth: "500px",
        }}
      >
        <thead>
          <tr>
            <th>Description</th>
            <th>
              <Typography textAlign="right">Status</Typography>
            </th>
            <th>
              <Typography textAlign="right">Payment Date</Typography>
            </th>
            <th>
              <Typography textAlign="right">Amount</Typography>
            </th>
          </tr>
        </thead>

        <tbody>
          {affiliates.map((affiliate, index) => (
            <PayoutItem key={index} item={affiliate} />
          ))}
        </tbody>
      </Table>
    </Sheet>
  ) : (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "200px",
      }}
      spacing={1.5}
    >
      <Typography
        textAlign="center"
        level={"title-lg"}
        fontWeight={400}
        textColor={"neutral.400"}
      >
        Not Enough Data
      </Typography>
      <Link to={urls.dashboard.children.codes.absolutePath}>
        <Typography
          textAlign="center"
          level={"title-sm"}
          fontWeight={400}
          textColor={"neutral.500"}
          sx={{
            textDecoration: "underline",
          }}
        >
          Share your Affiliate Code
        </Typography>
      </Link>
    </Stack>
  );
}
