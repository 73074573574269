import { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { authenticate, deAuthenticate } from "../../redux/auth";
import {
  setFetchedAffiliates,
  setAffiliateCode,
  setAffiliates,
} from "../../redux/affiliate";

import { useNavigate } from "react-router-dom";

import {
  getAuthorizationHeader,
  getUserCredentials,
} from "../../utils/helpers";
import urls from "../../utils/urls.json";
import Layout from "../../components/layout";
import Loading from "../loading";
import axios from "axios";
import { DOMAIN } from "../../utils/config";

export default function Protected({ restaurantLayout }) {
  const [loaded, setLoaded] = useState(false);

  const { fetchedAffiliates } = useSelector((state) => state.affiliate);
  const { isAuthenticated } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const setup = useCallback(() => {
    if (!fetchedAffiliates) {
      Promise.all([
        axios({
          method: "GET",
          url: `${DOMAIN}/affiliates/codes/`,
          headers: getAuthorizationHeader(),
        }),
        axios({
          method: "GET",
          url: `${DOMAIN}/affiliates/`,
          headers: getAuthorizationHeader(),
        }),
      ])
        .then(([affiliateCodesRes, affiliatesRes]) => {
          dispatch(setAffiliateCode(affiliateCodesRes.data[0]));
          dispatch(setAffiliates(affiliatesRes.data));
          dispatch(setFetchedAffiliates(true));
        })
        .catch((err) => console.error(err));
    }
  }, [fetchedAffiliates, dispatch]);

  useEffect(() => {
    const credentials = getUserCredentials();
    if (
      credentials.accessToken &&
      credentials.refreshToken &&
      credentials.user
    ) {
      dispatch(authenticate({ user: credentials.user }));
      setup();
    } else {
      dispatch(deAuthenticate());
      navigate(urls.auth.children.login.absolutePath);
    }
    setLoaded(true);
  }, [dispatch, navigate, setup]);

  return (
    <Loading loading={!loaded || !isAuthenticated} minHeight={"100vh"}>
      {restaurantLayout ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : (
        <Outlet />
      )}
    </Loading>
  );
}
