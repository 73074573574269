import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setNotify } from "../../../../redux/utils";

import {
  Button,
  Card,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Typography,
} from "@mui/joy";

import UpdatePassword from "./updatePassword";
import {
  getAuthorizationHeader,
  getUserCredentials,
  saveUserCredentials,
} from "../../../../utils/helpers";
import { DOMAIN } from "../../../../utils/config";

import axios from "axios";
import { authenticate } from "../../../../redux/auth";
import EditProfile from "./profile";

export default function UpdateAccount() {
  const user = useSelector((state) => state.auth.user);

  const [email] = useState(user.email);
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);

  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving(true);
    axios({
      method: "PUT",
      url: `${DOMAIN}/auth/user/`,
      headers: getAuthorizationHeader(),
      data: {
        first_name: firstName,
        last_name: lastName,
      },
    })
      .then((res) => {
        const { accessToken, refreshToken } = getUserCredentials();
        saveUserCredentials({
          access: accessToken,
          refresh: refreshToken,
          user: res.data,
        });
        dispatch(authenticate({ user: res.user }));
        dispatch(
          setNotify({
            open: true,
            action: "Profile Updated!",
            severity: "success",
          })
        );
        window.location.reload();
      })
      .catch((err) => console.error(err));
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "80vh",
      }}
    >
      <Container maxWidth="xs">
        <Stack spacing={2} pt={5}>
          <Card size="lg">
            <Typography level="title-lg" textAlign="center">
              My Account
            </Typography>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Stack spacing={1.5}>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input size="sm" disabled type="email" value={email} />
                </FormControl>
                <FormControl>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    size="sm"
                    required
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    size="sm"
                    required
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </FormControl>
                <Button
                  size="sm"
                  color="neutral"
                  type="submit"
                  disabled={saving}
                >
                  {saving ? "Saving" : "Save"}
                </Button>
              </Stack>
            </form>
            <UpdatePassword />
            <Divider orientation="horizontal" />
            <EditProfile />
          </Card>
        </Stack>
      </Container>
    </Stack>
  );
}
