import { extendTheme } from "@mui/joy/styles";

export const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: "#e9f0fc",
          100: "#e9f0fc",
          200: "#d3e2f8",
          300: "#7ba7ea",
          400: "#4f8ae3",
          500: "#2E75DF",
          600: "#226ddd",
          700: "#1f62c7",
          800: "#1c57b0",
          900: "#184c9a",
        },
        neutral: {
          50: "#fafafa",
          100: "#e6e6e6",
          200: "#cccccc",
          300: "#a6a6a6",
          400: "#8c8c8c",
          500: "#333333",
          600: "#262626",
          700: "#1a1a1a",
          800: "#0d0d0d",
          900: "#000000",
        },
        warning: {
          50: "#fff8e6",
          100: "#ffe9b3",
          200: "#ffdb80",
          300: "#ffcc4d",
          400: "#ffbe1a",
          500: "#F7B200",
          600: "#e6a400",
          700: "#cc9200",
          800: "#b38000",
          900: "#996e00",
        },
        danger: {
          50: "#ffe6e6",
          100: "#feb3b3",
          200: "#fe8181",
          300: "#fd4e4e",
          400: "#fd1c1c",
          600: "#F90202",
          500: "#e30202",
          700: "#ca0202",
          800: "#b10202",
          900: "#970202",
        },
        success: {
          50: "#b3ffc1",
          100: "#80ff97",
          200: "#4dff6d",
          300: "#1aff44",
          400: "#00e62a",
          500: "#00B620",
          600: "#00b321",
          700: "#00991c",
          800: "#008017",
          900: "#006613",
        },
      },
    },
  },
  components: {
    JoyListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
        },
      },
    },
  },
  fontSize: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    xl2: "1.5rem",
    xl3: "1.875rem",
    xl4: "2.25rem",
  },
});
