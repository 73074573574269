import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { Button, IconButton, Stack, Typography } from "@mui/joy";
import { MenuOutlined, PersonOutline } from "@mui/icons-material";

import urls from "../../../../utils/urls.json";
import { setSidebarOpen } from "../../../../redux/utils";

export default function Header() {
  const { user } = useSelector((state) => state.auth);
  const { activePathId } = useSelector((state) => state.utils);

  const dispatch = useDispatch();

  const getPageName = () => {
    if (activePathId) {
      const pathArray = [
        activePathId.split(".")[0],
        "children",
        activePathId.split(".").slice(1, activePathId.split(".").length),
        "name",
      ];
      return pathArray.reduce((acc, part) => acc?.[part], urls);
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        padding: {
          xs: "15px 20px",
          lg: "15px 35px",
        },
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <Typography
          level="title-lg"
          fontWeight={700}
          sx={{
            cursor: "pointer",
          }}
        >
          {getPageName()}
        </Typography>
      </Stack>

      <Button
        variant="plain"
        color="neutral"
        startDecorator={<PersonOutline />}
        component={Link}
        to="/account"
        sx={{
          display: {
            xs: "none",
            lg: "flex",
          },
        }}
      >
        {user.first_name} {user.last_name}
      </Button>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          display: {
            xs: "flex",
            lg: "none",
          },
        }}
      >
        <IconButton size="sm" component={Link} to="/account">
          <PersonOutline />
        </IconButton>
        <IconButton size="sm" onClick={() => dispatch(setSidebarOpen(true))}>
          <MenuOutlined />
        </IconButton>
      </Stack>
    </Stack>
  );
}
