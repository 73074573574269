import {
  ChildCare,
  Egg,
  FamilyRestroom,
  LocalFireDepartment,
  NoFood,
  PeopleOutline,
  Stars,
  WbSunny,
} from "@mui/icons-material";

// Save User Credentials
export const saveUserCredentials = (data) => {
  // helper function to save user credentials to the local storage
  localStorage.setItem("access", data.access);
  localStorage.setItem("refresh", data.refresh);
  localStorage.setItem("user", JSON.stringify(data.user));
};

// Delete User Credentials
export const deleteUserCredentials = (data) => {
  // helper function to delete user credentials from local storage
  localStorage.removeItem("access");
  localStorage.removeItem("refresh");
  localStorage.removeItem("user");
};

// Get User Credentials
export const getUserCredentials = () => {
  // helper function to fetch user credentials from local storage
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = {};
  }
  return {
    accessToken: localStorage.getItem("access"),
    refreshToken: localStorage.getItem("refresh"),
    user,
  };
};

export const getAuthorization = () => {
  const data = getUserCredentials();
  return `Bearer ${data["accessToken"]}`;
};

export const getAuthorizationHeader = () => {
  return {
    Authorization: getAuthorization(),
  };
};

export const removePrefixSpace = (string) => {
  if (string.length > 1) {
    if (string.slice(0, 1) === " ") {
      return string.slice(1, string.length);
    }
    return string;
  }
  return string;
};

export const breakMenusResponse = (menus) => {
  let menu = {};
  let categories = [];
  let items = [];
  let variants = [];

  if (menus.length) {
    menu = menus[0];

    categories = menu.categories;

    categories.forEach((category) => {
      category.items.forEach((item) => {
        item.variants.forEach((variant) => {
          variants.push(variant);
        });
        delete item["variants"];
        items.push(item);
      });
      delete category["items"];
    });

    delete menu["categories"];
  }

  return {
    menu: menu,
    itemCategories: categories,
    items: items,
    variants: variants,
  };
};

export const getTagIcon = (tag, props) => {
  switch (tag.code) {
    case "KIDS_CHOICE":
      return <ChildCare {...props} />;
    case "BEST_SELLER":
      return <Stars {...props} />;
    case "SPICY":
      return <LocalFireDepartment {...props} />;
    case "NO_ONION_AND_GARLIC":
      return <NoFood {...props} />;
    case "CONTAINS_EGG":
      return <Egg {...props} />;
    case "KIDS_FRIENDLY":
      return <ChildCare {...props} />;
    case "FAMILY_FRIENDLY":
      return <FamilyRestroom {...props} />;
    case "COUPLE_FRIENDLY":
      return <PeopleOutline {...props} />;
    case "ROOF_TOP":
      return <WbSunny {...props} />;
    default:
      return <ChildCare {...props} />;
  }
};
