import { Stack, Typography } from "@mui/joy";
import moment from "moment";

export default function EarningItem({ item }) {
  return (
    <tr>
      <td>
        <Typography level="body-md" fontWeight={700}>
          {item.product}
        </Typography>
      </td>
      <td align="right">
        <Stack spacing={0.25}>
          <Typography level="body-md" fontWeight={700} color="success">
            + ₹{item.amount}
          </Typography>
          <Typography level="body-xs" color="neutral">
            {moment(item.created_at).format("DD MMM, YYYY")}
          </Typography>
        </Stack>
      </td>
    </tr>
  );
}
