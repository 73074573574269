import { Typography } from "@mui/joy";
import moment from "moment";

export default function EarningItem({ item }) {
  return (
    <tr>
      <td>
        <Typography level="body-md" fontWeight={700}>
          {item.product}
        </Typography>
      </td>
      <td align="right">
        <Typography
          level="body-md"
          fontWeight={700}
          color={item.paid_at ? "success" : "warning"}
        >
          {item.paid_at ? "Paid" : "Pending"}
        </Typography>
      </td>
      <td align="right">
        <Typography level="body-md" fontWeight={500}>
          {item.paid_at
            ? moment(item.paid_at).format("DD MMM, YYYY")
            : "To be paid"}
        </Typography>
      </td>
      <td align="right">
        <Typography level="body-md" fontWeight={700}>
          ₹{item.amount}
        </Typography>
      </td>
    </tr>
  );
}
