import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setActivePathId, setNotify } from "../../../redux/utils";

import axios from "axios";

import { DOMAIN } from "../../../utils/config";
import { saveUserCredentials } from "../../../utils/helpers";

import urls from "../../../utils/urls.json";
import AuthWrapper from "../wrapper";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Typography,
} from "@mui/joy";

export default function Signup() {
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPassword1, setErrorPassword1] = useState(null);
  const [errorPassword2, setErrorPassword2] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setActivePathId(urls.auth.children.signup.pathId));
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // remove errors from state, if any
    setError(null);
    setErrorEmail(null);
    setErrorPassword1(null);
    setErrorPassword2(null);
    // set loading to true
    setLoading(true);

    try {
      // make API request
      const response = await axios({
        method: "POST",
        url: `${DOMAIN}/auth/registration/`,
        data: {
          email,
          password1,
          password2,
        },
      });
      // save user credentials
      saveUserCredentials(response.data);
      // redirect to home page

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Signed up Successfully!",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      navigate(urls.dashboard.absolutePath);
    } catch (err) {
      // set loading to false
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);

        // set error
        setErrorEmail(error_object.email && error_object.email[0]);
        setErrorPassword1(error_object.password1 && error_object.password1[0]);
        setErrorPassword2(error_object.password2 && error_object.password2[0]);
        setError(
          error_object.non_field_errors && error_object.non_field_errors[0]
        );
      } catch {
        // default error message
        setError("Unable to Sign up");
      }
    }
  };

  const handleChange = (func, value) => {
    // remove errors from state, if anny
    setError(null);
    setErrorEmail(null);
    setErrorPassword1(null);
    setErrorPassword2(null);
    func(value);
  };

  return (
    <AuthWrapper>
      <Stack spacing={2}>
        <Typography level="h3" component="h1" textAlign="center">
          {urls.auth.children.signup.name}
        </Typography>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Stack spacing={1.5}>
            <FormControl error={Boolean(errorEmail || error)}>
              <FormLabel>Email</FormLabel>
              <Input
                required
                size="sm"
                type="email"
                placeholder="john.doe@example.com"
                value={email}
                onChange={(e) => handleChange(setEmail, e.target.value)}
              />
              {Boolean(errorEmail) && (
                <FormHelperText>{errorEmail}</FormHelperText>
              )}
            </FormControl>
            <FormControl error={Boolean(errorPassword1 || error)}>
              <FormLabel>Password</FormLabel>
              <Input
                required
                size="sm"
                type="text"
                placeholder="Choose a Secure Password"
                value={password1}
                onChange={(e) => handleChange(setPassword1, e.target.value)}
              />
              {Boolean(errorPassword1) && (
                <FormHelperText>{errorPassword1}</FormHelperText>
              )}
            </FormControl>
            <FormControl error={Boolean(errorPassword1 || error)}>
              <FormLabel>Confirm Password</FormLabel>
              <Input
                required
                size="sm"
                type="password"
                placeholder="**********"
                value={password2}
                onChange={(e) => handleChange(setPassword2, e.target.value)}
              />
              {Boolean(errorPassword2) ||
                (Boolean(error) && (
                  <FormHelperText>{errorPassword2 || error}</FormHelperText>
                ))}
            </FormControl>
            <Button type="submit" size="sm" disabled={loading} color="neutral">
              {loading ? "Signing Up" : "Sign Up"}
            </Button>
          </Stack>
        </form>
        <Button
          fullWidth
          size="sm"
          variant="plain"
          onClick={() => navigate(urls.auth.children.login.absolutePath)}
          color="neutral"
        >
          Already have an Account?
        </Button>
      </Stack>
    </AuthWrapper>
  );
}
