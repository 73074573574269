import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import {
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Stack,
  Typography,
  Tooltip,
  Drawer,
} from "@mui/joy";

import {
  FeedbackOutlined,
  OpenInNew,
  AutoAwesome,
  AutoGraph,
  Link,
  QueryStatsOutlined,
  PaidOutlined,
  SavingsOutlined,
} from "@mui/icons-material";

import urls from "../../utils/urls.json";
import { setSidebarOpen } from "../../redux/utils";

export default function Sidebar({ width }) {
  const { activePathId, sidebarOpen } = useSelector((state) => state.utils);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const menuListAboveItems = [
    {
      title: urls.dashboard.children.summary.name,
      code: urls.dashboard.children.summary.pathId,
      link: urls.dashboard.children.summary.absolutePath,
      icon: <QueryStatsOutlined />,
      disabled: false,
      upgradeToEnterprise: false,
      upgradeToPro: false,
    },
    {
      title: urls.dashboard.children.earnings.name,
      code: urls.dashboard.children.earnings.pathId,
      link: urls.dashboard.children.earnings.absolutePath,
      icon: <PaidOutlined />,
      disabled: false,
      upgradeToEnterprise: false,
      upgradeToPro: false,
    },
    {
      title: urls.dashboard.children.payout.name,
      code: urls.dashboard.children.payout.pathId,
      link: urls.dashboard.children.payout.absolutePath,
      icon: <SavingsOutlined />,
      disabled: false,
      upgradeToEnterprise: false,
      upgradeToPro: false,
    },
    {
      title: urls.dashboard.children.codes.name,
      code: urls.dashboard.children.codes.pathId,
      link: urls.dashboard.children.codes.absolutePath,
      icon: <Link />,
      disabled: false,
      upgradeToEnterprise: false,
      upgradeToPro: false,
    },
  ];

  const menuListBelowItems = [
    {
      title: "Feedback",
      link: `https://forms.gle/athPkvhvMqYnYk86A`,
      openInNewTab: true,
      icon: <FeedbackOutlined />,
    },
  ];

  const Logo = () => (
    <Stack direction="row" spacing={0.5} alignItems="flex-end">
      <Typography level="h4" component="h1" fontWeight={500}>
        Smart
      </Typography>
      <Typography level="h4" component="h1" fontWeight={700}>
        Dine-in
      </Typography>
      <Typography level="body-sm" fontWeight={500}>
        Affiliate
      </Typography>
    </Stack>
  );

  const TooltipWrapper = ({ upgradeToPro, upgradeToEnterprise, children }) =>
    upgradeToPro || upgradeToEnterprise ? (
      <Tooltip
        title={`Upgrade to ${upgradeToPro ? "Pro" : "Enterprise"}`}
        variant="solid"
        size="sm"
        placement="right"
      >
        {children}
      </Tooltip>
    ) : (
      children
    );

  const MenuList = ({ menuList }) => (
    <List>
      <Stack spacing={1}>
        {menuList.map((item, index) => (
          <TooltipWrapper
            upgradeToPro={item.upgradeToPro}
            upgradeToEnterprise={item.upgradeToEnterprise}
            key={index}
          >
            <ListItem>
              <ListItemButton
                disabled={item.disabled}
                selected={activePathId === item.code}
                color={activePathId === item.code ? "primary" : "neutral"}
                onClick={() => {
                  if (item.openInNewTab) {
                    window.open(item.link, "_blank");
                  } else {
                    navigate(item.link);
                  }
                  dispatch(setSidebarOpen(false));
                }}
              >
                <ListItemDecorator>{item.icon}</ListItemDecorator>
                <ListItemContent
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  <Stack direction="row">
                    {item.title}
                    {item.upgradeToPro ? (
                      <sup
                        style={{
                          marginLeft: "5px",
                        }}
                      >
                        <AutoAwesome
                          color="warning"
                          sx={{
                            fontSize: "14px",
                          }}
                        />
                      </sup>
                    ) : null}
                    {item.upgradeToEnterprise ? (
                      <sup
                        style={{
                          marginLeft: "5px",
                        }}
                      >
                        <AutoGraph
                          color="warning"
                          sx={{
                            fontSize: "14px",
                          }}
                        />
                      </sup>
                    ) : null}
                    {item.openInNewTab && (
                      <sup>
                        <OpenInNew
                          sx={{
                            height: "10px",
                          }}
                        />
                      </sup>
                    )}
                  </Stack>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
          </TooltipWrapper>
        ))}
      </Stack>
    </List>
  );

  const SidebarWrapper = ({ children }) => (
    <>
      <Stack
        sx={{
          width: `${width}px`,
          bgcolor: "#fff",
          height: "100vh",
          position: "sticky",
          top: 0,
          display: {
            xs: "none",
            lg: "flex",
          },
        }}
        boxShadow="sm"
        justifyContent="space-between"
        spacing={2}
      >
        {children}
      </Stack>
      <Drawer
        open={sidebarOpen}
        onClose={() => dispatch(setSidebarOpen(false))}
        sx={{
          display: {
            xs: "flex",
            lg: "none",
          },
        }}
      >
        <Stack
          sx={{
            bgcolor: "#fff",
            height: "100vh",
            position: "sticky",
            top: 0,
          }}
          boxShadow="sm"
          justifyContent="space-between"
          spacing={2}
        >
          {children}
        </Stack>
      </Drawer>
    </>
  );

  return (
    <SidebarWrapper>
      <Stack px={2.5} pt={2}>
        <Stack spacing={1}>
          <Logo />
          <MenuList menuList={menuListAboveItems} />
        </Stack>
      </Stack>
      <Stack px={2.5} pb={2}>
        <MenuList menuList={menuListBelowItems} />
      </Stack>
    </SidebarWrapper>
  );
}
