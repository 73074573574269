import { CircularProgress, Stack } from "@mui/joy";

export default function Loading({ loading, minHeight, children }) {
  const Progress = () => (
    <Stack
      justifyContent="center"
      alignItems="center"
      minHeight={minHeight || "75vh"}
    >
      <CircularProgress color="neutral" />
    </Stack>
  );

  return loading ? <Progress /> : children;
}
