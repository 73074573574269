import { Stack } from "@mui/joy";
import Header from "./header";
import UpdateAccount from "./updateAccount";

export default function Account() {
  return (
    <Stack>
      <Header />
      <UpdateAccount />
    </Stack>
  );
}
