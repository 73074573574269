import { Grid, Stack } from "@mui/joy";
import DailyEarnings from "./dailyEarnings";
import PaymentCard from "./paymentCard";

export default function Body() {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "200px",
      }}
    >
      <Grid container spacing={3}>
        <Grid xs={12} lg={4}>
          <PaymentCard
            title="Total Earning"
            description="This is the total amount of money you have made so far from Smart Dine-in's Affiliate Program."
            amount={null}
            color="neutral.500"
          />
        </Grid>
        <Grid xs={12} lg={4}>
          <PaymentCard
            title="Paid"
            description="This is the total amount of money transferred to your bank account."
            amount={null}
            color="neutral.500"
          />
        </Grid>
        <Grid xs={12} lg={4}>
          <PaymentCard
            title="Pending"
            description="This is the latest amount of money you have made from Smart Dine-in's Affiliate Program. It'll be transferred to you shortly."
            amount={null}
            color="warning.500"
          />
        </Grid>
        <Grid xs={12}>
          <DailyEarnings />
        </Grid>
      </Grid>
    </Stack>
  );
}
