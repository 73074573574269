import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setNotify } from "../../../../redux/utils";

import { Button, FormControl, FormLabel, Input, Stack } from "@mui/joy";

import {
  getAuthorizationHeader,
  getUserCredentials,
  saveUserCredentials,
} from "../../../../utils/helpers";
import { authenticate } from "../../../../redux/auth";

import { DOMAIN } from "../../../../utils/config";

import axios from "axios";

export default function EditProfile() {
  const { user } = useSelector((state) => state.auth);

  const [upiId, setUpiId] = useState(user.profile.upi_id);
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving(true);
    axios({
      method: "PATCH",
      url: `${DOMAIN}/accounts/profile/`,
      headers: getAuthorizationHeader(),
      data: {
        upi_id: upiId,
      },
    })
      .then((res) => {
        const userData = {
          ...user,
          profile: res.data,
        };
        const { accessToken, refreshToken } = getUserCredentials();
        saveUserCredentials({
          access: accessToken,
          refresh: refreshToken,
          user: userData,
        });
        dispatch(
          authenticate({
            user: userData,
          })
        );
        dispatch(
          setNotify({
            open: true,
            action: "Profile Updated!",
            severity: "success",
          })
        );
        setSaving(false);
      })
      .catch((err) => console.error(err));
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Stack spacing={1.5}>
        <FormControl>
          <FormLabel>Payout UPI ID</FormLabel>
          <Input
            size="sm"
            required
            type="text"
            value={upiId || ""}
            onChange={(e) => setUpiId(e.target.value)}
          />
        </FormControl>
        <Button size="sm" color="neutral" type="submit" disabled={saving}>
          {saving ? "Saving" : "Save"}
        </Button>
      </Stack>
    </form>
  );
}
